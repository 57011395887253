import React, { useState } from "react";
import * as classes from "./index.module.less";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { formatBlogDate } from "src/utils/functions";
import Layout from "components/shared/Layout";
import og from "images/other/og.jpeg";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let Item = ({ node, category }) => {
  const image = getImage(node?.featuredImage?.node?.localFile);
  return (
    <>
      <Link to={`/${category}/${node.slug}/`} className={classes.item}>
        <span className={classes.text}>
          <h2>{node.custom_data.previewTitle}</h2>
          <p>{node.custom_data.previewDescription}</p>
          <p className={classes.date}>{formatBlogDate(node.date)}</p>
        </span>
        {image ? (
          <div className={classes.image}>
            <GatsbyImage
              image={image}
              alt={node.custom_data.previewTitle}
              loading="lazy"
              width={360}
              height={240}
            />
          </div>
        ) : (
          <img src={og} alt="Blog Post" />
        )}
      </Link>
      <p className={classes.altDesc}>{node.custom_data.previewDescription}</p>
    </>
  );
};

let Filter = ({ posts, selectedDate, setSelectedDate }) => {
  let items = posts.reduce((acc, { node }) => {
    let month = new Date(node.date).getMonth();
    let year = new Date(node.date).getFullYear();
    if (
      !acc.find(
        (savedDate) => savedDate.month === month && savedDate.year === year
      )
    ) {
      return [...acc, { month, year }];
    } else {
      return acc;
    }
  }, []);

  return (
    <>
      <div className={classes.filter}>
        <Layout>
          <label htmlFor="filter">Filter By Month:</label>
          <select
            name="filter"
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
            value={selectedDate}
          >
            <option value="">Not Selected</option>
            {items.map((it, index) => (
              <option value={`${it.month}-${it.year}`} key={index}>
                {`${MONTHS[it.month]} ${it.year}`}
              </option>
            ))}
          </select>
        </Layout>
      </div>
    </>
  );
};

export default ({ posts, category, intro }) => {
  let [selectedDate, setSelectedDate] = useState("");

  let filteredPosts = () => {
    if (selectedDate === "") return posts;
    let [month, year] = selectedDate.split("-");
    return posts.filter(({ node }) => {
      let postMonth = new Date(node.date).getMonth();
      let postYear = new Date(node.date).getFullYear();
      return (
        parseInt(postMonth) === parseInt(month) &&
        parseInt(postYear) === parseInt(year)
      );
    });
  };

  return (
    <div className={classes.container}>
      <Filter
        posts={posts}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <div className={classes.intro}>{intro}</div>

      <Layout>
        <div className={classes.content}>
          {filteredPosts().map(
            (it, index) =>
              it?.node && <Item key={index} {...it} category={category} />
          )}
        </div>
      </Layout>
    </div>
  );
};
