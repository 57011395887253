import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import BlogCards from "components/blog/BlogCards";
import icon from "images/svg_icons/digital-marketing.svg";
import { graphql } from "gatsby";
import LargeTitle from "components/services/LargeTitle";

export default ({ data }) => (
  <>
    <Meta url="/blog/" />
    <Nav />
    <LargeTitle
      size="small"
      img={icon}
      green="The AppInChina"
      black="Blog"
      text="Read and subscribe to our blog to catch up on the latest industry trends and news from China."
    />
    <BlogCards posts={data.allWpPost.edges} category="blog" />
    <CTA />
    <Footer />
  </>
);

export const pageQuery = graphql`
  query {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "blog" } } } } }
    ) {
      edges {
        node {
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          custom_data {
            metaDescription
            previewDescription
            previewTitle
          }
          slug
          title
          date
        }
      }
    }
  }
`;
