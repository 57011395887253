import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";

export default ({ green, black, img, text, size }) => (
  <Layout>
    <div className={[classes.content /*classes[size]*/].join(" ")}>
      <h1>
        <span>{green}</span>
        {" "}
        <span>{black}</span>
      </h1>
      <img src={img} alt={green} className={/*classes[size]*/ ""} />
    </div>
    {text ? <p className={classes.gray}>{text}</p> : null}
  </Layout>
);
