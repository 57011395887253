import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import right from "images/svg_icons/right.svg";
import { TrackAnalyticsEvent } from "src/utils/functions";

let setLocalStorage = (key, defaultValue) => {if (typeof window !== "undefined") {
  window.localStorage.setItem(key, JSON.stringify(defaultValue));
}};

export default ({ titleLevel = "" }) => {
  let title_text = "Fearlessly Enter The World’s Largest App Market";
  let titleRender = "";
  if (titleLevel == "") {
    titleRender = <h4>{title_text}</h4>;
  } else if (titleLevel == "h3") {
    titleRender = <h3>{title_text}</h3>;
  } else if (titleLevel == "h2") {
    titleRender = <h2>{title_text}</h2>;
  }

  return (
    <aside className={classes.container}>
      <Layout>
        <div className={classes.content}>
          {titleRender}
          <p>
            <b>Send us a message</b> with your questions. We'll put together a
            custom plan tailored to your needs.
          </p>
          <div
            onClick={() => {
              TrackAnalyticsEvent("Get Started Clicked", "CTA button clicked")
              setLocalStorage("ctaId", "generalFooterCTA");
            }}
          >
            <Link to="/get-started/" className={classes.black}>
              Get Started Now
              <img alt="icon" src={right} />
            </Link>
          </div>
        </div>
        <StaticImage
          src="../../../images/other/dashboard.png"
          alt="Dashboard"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Layout>
    </aside>
  );
};
